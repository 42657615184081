import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, query, where, deleteDoc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import Sidebar from '../components/Sidebar';
import './CrewMembers.css';
import '../styles/SharedStyles.css';
import { serverTimestamp } from 'firebase/firestore';

function CrewMembers() {
  const [crewMembers, setCrewMembers] = useState([]);
  const [filteredCrewMembers, setFilteredCrewMembers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [newCrewMember, setNewCrewMember] = useState({
    name: '',
    email: '',
    role: '',
    production: '',
    supervisor: '',
    department: '',
    rate: '',
    union: '' // Add union field
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const [departmentMap, setDepartmentMap] = useState({});

  useEffect(() => {
    if (user) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchCrewMembers(),
        fetchProductions(),
        fetchDepartments()
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCrewMembers = async () => {
    try {
      const usersCollection = collection(db, 'users');
      let usersQuery;

      if (user && (user.role === 'superAdmin' || user.role === 'admin' || user.role.toLowerCase() === 'supervisor' || user.role === 'Supervisor')) {
        usersQuery = usersCollection;
      } else {
        usersQuery = query(usersCollection, where('role', '==', 'crewMember'));
      }

      const querySnapshot = await getDocs(usersQuery);
      const crewMemberList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          lastFiveSSN: data.lastFiveSSN || 'Not provided' // Use the correct field name
        };
      });
      setCrewMembers(crewMemberList);
      setFilteredCrewMembers(crewMemberList);
    } catch (error) {
      console.error("Error fetching crew members: ", error);
      throw error;
    }
  };

  const fetchProductions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'productions'));
      const productionList = querySnapshot.docs.map(doc => doc.data().name);
      setProductions(productionList);
    } catch (error) {
      console.error("Error fetching productions: ", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'departments'));
      const departmentList = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
      setDepartments(departmentList);
      
      // Create a map of department IDs to names
      const deptMap = {};
      departmentList.forEach(dept => {
        deptMap[dept.id] = dept.name;
      });
      setDepartmentMap(deptMap);
    } catch (error) {
      console.error("Error fetching departments:", error);
      setError("Failed to fetch departments. Please try again.");
    }
  };

  useEffect(() => {
    let result = crewMembers;

    if (selectedProduction) {
      result = result.filter(member => member.production === selectedProduction);
    }

    if (selectedDepartment) {
      result = result.filter(member => member.department === selectedDepartment);
    }

    if (searchTerm) {
      result = result.filter(member => 
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredCrewMembers(result);
  }, [selectedProduction, selectedDepartment, searchTerm, crewMembers]);

  const handleProductionChange = (e) => setSelectedProduction(e.target.value);
  const handleDepartmentChange = (e) => setSelectedDepartment(e.target.value);
  const handleSearch = (e) => setSearchTerm(e.target.value);

  const generateTemporaryPassword = () => {
    // Implement a function to generate a temporary password
    return 'TempPass123!'; // This is just an example, use a more secure method in production
  };

  const sendWelcomeEmail = async (email) => {
    // Implement a function to send a welcome email
    // This would typically be handled by a Cloud Function
    console.log(`Sending welcome email to ${email}`);
  };

  const handleAddCrewMember = async (e) => {
    e.preventDefault();
    
    if (!(user.role === 'admin' || user.role === 'superAdmin' || user.role === 'supervisor' || user.role === 'Supervisor')) {
      setError("You don't have permission to add new crew members.");
      return;
    }
    
    try {
      // Create auth user
      const userCredential = await createUserWithEmailAndPassword(auth, newCrewMember.email, generateTemporaryPassword());
      
      // Create user document in Firestore 'users' collection
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: newCrewMember.email,
        name: newCrewMember.name,
        supervisor: newCrewMember.supervisor,
        production: newCrewMember.production,
        role: newCrewMember.role,
        department: newCrewMember.department,
        rate: parseFloat(newCrewMember.rate) || 0,
        union: newCrewMember.union, // Add union field
        createdAt: serverTimestamp()
      });

      // Trigger welcome email (this would be handled by a Cloud Function)
      await sendWelcomeEmail(newCrewMember.email);

      // Clear form and refresh crew members list
      setNewCrewMember({ name: '', email: '', role: '', production: '', supervisor: '', department: '', rate: '', union: '' });
      fetchCrewMembers();

      console.log('New crew member added to users collection');
    } catch (error) {
      console.error("Error adding crew member: ", error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please use a different email.');
      } else if (error.code === 'auth/invalid-email') {
        setError('The email address is not valid.');
      } else if (error.code === 'auth/weak-password') {
        setError('The password is too weak. Please use a stronger password.');
      } else {
        setError('An error occurred while adding the crew member. Please try again.');
      }
    }
  };

  const handleRemoveFromProduction = async (memberId) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { production: null });
      fetchCrewMembers();
    } catch (error) {
      console.error("Error removing from production: ", error);
    }
  };

  const handleAssignSupervisor = async (memberId, supervisorId) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { supervisor: supervisorId });
      fetchCrewMembers();
    } catch (error) {
      console.error("Error assigning supervisor: ", error);
    }
  };

  const handleAssignProduction = async (memberId, productionName) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { production: productionName });
      fetchCrewMembers();
    } catch (error) {
      console.error("Error assigning production: ", error);
      setError("Failed to assign production. Please try again.");
    }
  };

  const handleUpdateRate = async (memberId, newRate) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { rate: parseFloat(newRate) });
      fetchCrewMembers();
    } catch (error) {
      console.error("Error updating rate: ", error);
      setError("Failed to update rate. Please try again.");
    }
  };

  const handleUpdateUnion = async (memberId, newUnion) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { union: newUnion });
      fetchCrewMembers();
    } catch (error) {
      console.error("Error updating union: ", error);
      setError("Failed to update union. Please try again.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <div className="crew-members-container">
      <Sidebar />
      <div className="crew-members-content">
        <header className="page-header">
          <h1>Crew Members</h1>
          <p>Manage and organize your production crew</p>
        </header>
        <main>
          {error && <div className="error-message">{error}</div>}
          <section className="add-crew-member">
            <h2>Add New Crew Member</h2>
            <form onSubmit={handleAddCrewMember}>
              <input
                type="text"
                placeholder="Name"
                value={newCrewMember.name}
                onChange={(e) => setNewCrewMember({...newCrewMember, name: e.target.value})}
                required
              />
              <input
                type="email"
                placeholder="Email"
                value={newCrewMember.email}
                onChange={(e) => setNewCrewMember({...newCrewMember, email: e.target.value})}
                required
              />
              <select
                value={newCrewMember.role}
                onChange={(e) => setNewCrewMember({...newCrewMember, role: e.target.value})}
                required
              >
                <option value="">Select Role</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Crew Member">Crew Member</option>
              </select>
              <select
                value={newCrewMember.production}
                onChange={(e) => setNewCrewMember({...newCrewMember, production: e.target.value})}
                required
              >
                <option value="">Select Production</option>
                {productions.map(prod => (
                  <option key={prod} value={prod}>{prod}</option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Supervisor"
                value={newCrewMember.supervisor}
                onChange={(e) => setNewCrewMember({...newCrewMember, supervisor: e.target.value})}
              />
              {/* Add new department dropdown */}
              <select
                value={newCrewMember.department}
                onChange={(e) => setNewCrewMember({...newCrewMember, department: e.target.value})}
                required
              >
                <option value="">Select Department</option>
                {departments.map(dept => (
                  <option key={dept.id} value={dept.id}>{dept.name}</option>
                ))}
              </select>
              <input
                type="number"
                step="0.01"
                placeholder="Hourly Rate"
                value={newCrewMember.rate}
                onChange={(e) => setNewCrewMember({...newCrewMember, rate: e.target.value})}
                required
              />
              <input
                type="text"
                placeholder="Union"
                value={newCrewMember.union}
                onChange={(e) => setNewCrewMember({...newCrewMember, union: e.target.value})}
              />
              <button type="submit">Add Crew Member</button>
            </form>
          </section>
          <section className="crew-filters">
            <select onChange={handleProductionChange} value={selectedProduction}>
              <option value="">All Productions</option>
              {productions.map(production => (
                <option key={production} value={production}>{production}</option>
              ))}
            </select>
            <select onChange={handleDepartmentChange} value={selectedDepartment}>
              <option value="">All Departments</option>
              {departments.map(department => (
                <option key={department.id} value={department.id}>{department.name}</option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Search crew members..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </section>
          <section className="crew-member-list">
            <h2>Crew Members</h2>
            <div className="crew-member-grid">
              {filteredCrewMembers.map((member) => (
                <div key={member.id} className="crew-member-item">
                  <h3>{member.name}</h3>
                  <p>Email: {member.email}</p>
                  <p>SSN (last 5): {member.lastFiveSSN}</p> 
                  <p>Role: {member.role}</p>
                  <p>Department: {departmentMap[member.department] || 'Not assigned'}</p>
                  <p>Production: {member.production || 'Not assigned'}</p>
                  <p>Supervisor: {crewMembers.find(m => m.id === member.supervisor)?.name || 'None'}</p>
                  <p>Rate: ${member.rate ? member.rate.toFixed(2) : '0.00'}/hr</p>
                  <p>Union: {member.union || 'Not specified'}</p>
                  <input
                    type="text"
                    placeholder="Update Union"
                    onChange={(e) => handleUpdateUnion(member.id, e.target.value)}
                  />
                  <input
                    type="number"
                    step="0.01"
                    placeholder="Update Rate"
                    onChange={(e) => handleUpdateRate(member.id, e.target.value)}
                  />
                  <select
                    value={member.supervisor || ''}
                    onChange={(e) => handleAssignSupervisor(member.id, e.target.value)}
                  >
                    <option value="">Assign Supervisor</option>
                    {crewMembers.filter(m => m.id !== member.id && m.role === 'Supervisor').map(m => (
                      <option key={m.id} value={m.id}>{m.name}</option>
                    ))}
                  </select>
                  <select
                    value={member.production || ''}
                    onChange={(e) => handleAssignProduction(member.id, e.target.value)}
                  >
                    <option value="">Assign Production</option>
                    {productions.map(prod => (
                      <option key={prod} value={prod}>{prod}</option>
                    ))}
                  </select>
                  {member.production && (
                    <button onClick={() => handleRemoveFromProduction(member.id)}>
                      Remove from Production
                    </button>
                  )}
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default CrewMembers;