import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, increment } from 'firebase/firestore';

export function useAuth() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const idTokenResult = await firebaseUser.getIdTokenResult(true);
          console.log('User custom claims:', idTokenResult.claims);

          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            
            // Increment login count
            await updateDoc(userDocRef, {
              loginCount: increment(1)
            });

            const updatedUserDoc = await getDoc(userDocRef);
            const updatedUserData = updatedUserDoc.data();

            const user = {
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              role: userData.role || idTokenResult.claims.role,
              department: userData.department,
              productionId: userData.productionId || idTokenResult.claims.productionId || null,
              isProductionAdmin: idTokenResult.claims.role === 'productionAdmin' || userData.role === 'productionAdmin',
              loginCount: updatedUserData.loginCount || 0,
              profileCompleted: updatedUserData.profileCompleted || false
            };
            setUser(user);
            console.log('User data set:', JSON.stringify(user, null, 2));
          } else {
            console.error('User document does not exist in Firestore');
            setUser(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return { user, setUser };
}