import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import './ReceiptViewer.css';

function ReceiptViewer() {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    fetchReceipts();
  }, [user]);

  const fetchReceipts = async () => {
    if (!user || !user.productionId) return;

    setLoading(true);
    try {
      const receiptsRef = collection(db, 'receipts');
      const q = query(receiptsRef, where('productionId', '==', user.productionId));
      const querySnapshot = await getDocs(q);
      
      const receiptData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setReceipts(receiptData);
    } catch (err) {
      console.error("Error fetching receipts:", err);
      setError("Failed to load receipts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading receipts...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="receipt-viewer">
      <h2>Submitted Receipts</h2>
      {receipts.length === 0 ? (
        <p>No receipts submitted yet.</p>
      ) : (
        <div className="receipt-list">
          {receipts.map(receipt => (
            <div key={receipt.id} className="receipt-item">
              <h3>{receipt.type} Receipt</h3>
              <img src={receipt.imageUrl} alt="Receipt" className="receipt-image" />
              <p><strong>Description:</strong> {receipt.description}</p>
              <p><strong>Submitted by:</strong> {receipt.submittedBy}</p>
              <p><strong>Date:</strong> {receipt.submissionDate.toDate().toLocaleString()}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ReceiptViewer;
