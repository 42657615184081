import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import './AddVehiclePopup.css';

function AddVehiclePopup({ onClose }) {
  const { user } = useAuth();
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    licensePlate: '',
    unitNumber: '',
  });
  const [vehicleImages, setVehicleImages] = useState([]);
  const [damageImages, setDamageImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle({ ...newVehicle, [name]: value });
  };

  const handleFileChange = (e, setFileFunction) => {
    if (e.target.files) {
      setFileFunction(Array.from(e.target.files));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storage = getStorage();
      const uploadFiles = async (files, folder) => {
        const urls = [];
        for (const file of files) {
          const imageRef = ref(storage, `${folder}/${file.name}`);
          await uploadBytes(imageRef, file);
          const url = await getDownloadURL(imageRef);
          urls.push(url);
        }
        return urls;
      };

      const vehicleImageUrls = await uploadFiles(vehicleImages, 'vehicles');
      const damageImageUrls = await uploadFiles(damageImages, 'damage');
      const documentUrls = await uploadFiles(documents, 'documents');

      const db = getFirestore();
      const vehicleData = {
        ...newVehicle,
        vehicleImageUrls,
        damageImageUrls,
        documentUrls,
        userId: user.uid
      };
      await addDoc(collection(db, 'vehicles'), vehicleData);
      
      alert('Vehicle added successfully!');
      onClose();
    } catch (error) {
      console.error("Error adding vehicle: ", error);
      alert('Error adding vehicle. Please try again.');
    }
  };

  return (
    <div className="add-vehicle-popup">
      <div className="add-vehicle-popup-content">
        <h2>Add New Vehicle</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="make"
            value={newVehicle.make}
            onChange={handleInputChange}
            placeholder="Make"
            required
          />
          <input
            type="text"
            name="model"
            value={newVehicle.model}
            onChange={handleInputChange}
            placeholder="Model"
            required
          />
          <input
            type="number"
            name="year"
            value={newVehicle.year}
            onChange={handleInputChange}
            placeholder="Year"
            required
          />
          <input
            type="text"
            name="licensePlate"
            value={newVehicle.licensePlate}
            onChange={handleInputChange}
            placeholder="License Plate"
            required
          />
          <input
            type="text"
            name="unitNumber"
            value={newVehicle.unitNumber}
            onChange={handleInputChange}
            placeholder="Unit Number"
            required
          />
          <div className="file-input-container">
            <label htmlFor="vehicleImages">Vehicle Pictures:</label>
            <input
              type="file"
              id="vehicleImages"
              onChange={(e) => handleFileChange(e, setVehicleImages)}
              accept="image/*"
              multiple
            />
          </div>
          <div className="file-input-container">
            <label htmlFor="damageImages">Damage Pictures:</label>
            <input
              type="file"
              id="damageImages"
              onChange={(e) => handleFileChange(e, setDamageImages)}
              accept="image/*"
              multiple
            />
          </div>
          <div className="file-input-container">
            <label htmlFor="documents">Document Pictures:</label>
            <input
              type="file"
              id="documents"
              onChange={(e) => handleFileChange(e, setDocuments)}
              accept="image/*"
              multiple
            />
          </div>
          {vehicleImages.length > 0 && (
            <div className="selected-files">
              <p>Selected vehicle images:</p>
              <ul>
                {vehicleImages.map((image, index) => (
                  <li key={index}>{image.name}</li>
                ))}
              </ul>
            </div>
          )}
          {damageImages.length > 0 && (
            <div className="selected-files">
              <p>Selected damage images:</p>
              <ul>
                {damageImages.map((image, index) => (
                  <li key={index}>{image.name}</li>
                ))}
              </ul>
            </div>
          )}
          {documents.length > 0 && (
            <div className="selected-files">
              <p>Selected documents:</p>
              <ul>
                {documents.map((doc, index) => (
                  <li key={index}>{doc.name}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="button-group">
            <button type="submit">Add Vehicle</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddVehiclePopup;