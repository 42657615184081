import React, { useState, useEffect, useContext } from 'react';
import { collection, query, where, getDocs, orderBy, limit, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import './Notifications.css';
import '../styles/SharedStyles.css';

function Notifications() {
  const { user } = useContext(AuthContext);
  const [receivedNotifications, setReceivedNotifications] = useState([]);
  const [sentNotifications, setSentNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  const refreshNotifications = () => {
    setPage(1);
    setHasMore(true);
    fetchNotifications();
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      console.log('Fetching notifications for user:', user.uid);

      const pageSize = 20; // Adjust this value as needed

      // Fetch received notifications (keep this part as is)
      const receivedNotificationsQuery = query(
        collection(db, 'notifications'),
        where('userId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        limit(pageSize * page)
      );
      const receivedNotificationsSnapshot = await getDocs(receivedNotificationsQuery);
      console.log('Received notifications fetched:', receivedNotificationsSnapshot.size);

      const receivedNotificationsData = receivedNotificationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        type: 'received'
      }));

      // Fetch sent notifications
      const sentNotificationsQuery = query(
        collection(db, 'notifications'),
        where('senderId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        limit(pageSize * page)
      );
      const sentNotificationsSnapshot = await getDocs(sentNotificationsQuery);
      console.log('Sent notifications fetched:', sentNotificationsSnapshot.size);

      const sentNotificationsData = sentNotificationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        type: 'sent'
      }));

      // Fetch dispatched times (both received and sent)
      const dispatchedTimesQuery = query(
        collection(db, 'timeEntries'),
        where('createdAt', '>', Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))),
        orderBy('createdAt', 'desc')
      );
      const dispatchedTimesSnapshot = await getDocs(dispatchedTimesQuery);
      console.log('Dispatched times fetched:', dispatchedTimesSnapshot.size);

      const dispatchedTimesData = dispatchedTimesSnapshot.docs
        .filter(doc => doc.data().userId === user.uid || doc.data().createdBy === user.uid)
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: doc.data().userId === user.uid ? 'dispatchedTime' : 'sentDispatchedTime'
        }));

      // Fetch dispatched runs (both received and sent)
      const dispatchedRunsQuery = query(
        collection(db, 'dispatchedRuns'),
        where('createdAt', '>', Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))),
        orderBy('createdAt', 'desc')
      );
      const dispatchedRunsSnapshot = await getDocs(dispatchedRunsQuery);
      console.log('Dispatched runs fetched:', dispatchedRunsSnapshot.size);

      const dispatchedRunsData = dispatchedRunsSnapshot.docs
        .filter(doc => doc.data().assignedTo === user.uid || doc.data().createdBy === user.uid)
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: doc.data().assignedTo === user.uid ? 'dispatchedRun' : 'sentDispatchedRun'
        }));

      // Update the state setting to handle pagination
      if (receivedNotificationsData.length < pageSize * page) {
        setHasMore(false);
      }

      const allReceivedNotifications = [...receivedNotificationsData, ...dispatchedTimesData.filter(n => n.type === 'dispatchedTime'), ...dispatchedRunsData.filter(n => n.type === 'dispatchedRun')];
      const allSentNotifications = [...sentNotificationsData, ...dispatchedTimesData.filter(n => n.type === 'sentDispatchedTime'), ...dispatchedRunsData.filter(n => n.type === 'sentDispatchedRun')];

      setReceivedNotifications(prevNotifications => 
        page === 1 ? allReceivedNotifications : [...prevNotifications, ...allReceivedNotifications]
      );
      setSentNotifications(prevNotifications => 
        page === 1 ? allSentNotifications : [...prevNotifications, ...allSentNotifications]
      );

      setLoading(false);
    } catch (error) {
      console.error('Detailed error fetching notifications:', error);
      setError(`Failed to fetch notifications: ${error.message}. Please try again.`);
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleString();
    }
    return 'Unknown date';
  };

  const renderNotification = (notification) => {
    switch (notification.type) {
      case 'dispatchedTime':
      case 'sentDispatchedTime':
        return (
          <div className={`notification ${notification.type}`}>
            <h3>{notification.type === 'dispatchedTime' ? 'Time Dispatched' : 'Time Sent'}</h3>
            <p>Date: {formatTimestamp(notification.date)}</p>
            <p>Call Time: {notification.startTime || 'Not set'}</p>
            <p>Wrap Time: {notification.endTime || 'Not set'}</p>
            {notification.type === 'sentDispatchedTime' && <p>Sent to: {notification.userId || 'Unknown user'}</p>}
          </div>
        );
      case 'dispatchedRun':
      case 'sentDispatchedRun':
        return (
          <div className={`notification ${notification.type}`}>
            <h3>{notification.type === 'dispatchedRun' ? 'Run Dispatched' : 'Run Sent'}</h3>
            {notification.imageUrl && (
              <img src={notification.imageUrl} alt="Run Image" className="run-image" />
            )}
            <p>Title: {notification.title}</p>
            <p>Date: {formatTimestamp(notification.date)}</p>
            <p>Type: {notification.runType}</p>
            <p>Status: {notification.status}</p>
            {notification.type === 'sentDispatchedRun' && <p>Sent to: {notification.assignedTo || 'Unknown user'}</p>}
          </div>
        );
      default:
        return (
          <div className="notification">
            <h3>{notification.title}</h3>
            <p>{notification.message}</p>
            <small>{formatTimestamp(notification.timestamp)}</small>
            {notification.type === 'sent' && <p>Sent to: {notification.recipientName || 'Unknown recipient'}</p>}
          </div>
        );
    }
  };

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
    fetchNotifications();
  };

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="notifications-container">
      <Sidebar />
      <div className="notifications-content">
        <header className="page-header">
          <h1>Notifications</h1>
          <button onClick={refreshNotifications} className="refresh-button">
            Refresh
          </button>
        </header>
        <main>
          <section className="received-notifications">
            <h2>Received Notifications</h2>
            {receivedNotifications.length === 0 ? (
              <p>No received notifications to display.</p>
            ) : (
              <ul className="notifications-list">
                {receivedNotifications.map(notification => (
                  <li key={notification.id} className={`notification-item ${notification.status}`}>
                    {renderNotification(notification)}
                  </li>
                ))}
              </ul>
            )}
            {hasMore && !loading && (
              <button onClick={loadMore} className="load-more-button">
                Load More
              </button>
            )}
          </section>
          <section className="sent-notifications">
            <h2>Sent Notifications</h2>
            {sentNotifications.length === 0 ? (
              <p>No sent notifications to display.</p>
            ) : (
              <ul className="notifications-list">
                {sentNotifications.map(notification => (
                  <li key={notification.id} className={`notification-item ${notification.status}`}>
                    {renderNotification(notification)}
                  </li>
                ))}
              </ul>
            )}
          </section>
        </main>
      </div>
    </div>
  );
}

export default Notifications;