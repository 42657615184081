import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, query, where, orderBy, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import { AuthContext } from '../contexts/AuthContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './Reports.css';
import '../styles/SharedStyles.css';

function Reports() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const [departmentMap, setDepartmentMap] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDepartment, setCurrentDepartment] = useState('');
  const [currentProduction, setCurrentProduction] = useState('');

  useEffect(() => {
    if (user) {
      fetchDepartments();
      fetchTimeEntries();
    }
  }, [user, selectedDate]);

  const fetchDepartments = async () => {
    try {
      const departmentsSnapshot = await getDocs(collection(db, 'departments'));
      const deptMap = {};
      departmentsSnapshot.forEach(doc => {
        deptMap[doc.id] = doc.data().name;
      });
      setDepartmentMap(deptMap);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchTimeEntries = async () => {
    try {
      setLoading(true);
      console.log("Fetching time entries for date:", selectedDate);

      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      console.log("Date range:", startOfDay, endOfDay);

      let timeEntriesQuery = query(
        collection(db, 'timeEntries'),
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay)),
        orderBy('date', 'asc')
      );

      const querySnapshot = await getDocs(timeEntriesQuery);
      console.log("Query snapshot size:", querySnapshot.size);

      const userIds = new Set(querySnapshot.docs.map(doc => doc.data().userId));
      const userDocs = await Promise.all(
        Array.from(userIds).map(userId => getDoc(doc(db, 'users', userId)))
      );
      const userDataMap = Object.fromEntries(
        userDocs.map(userDoc => [userDoc.id, userDoc.data()])
      );

      const entries = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const userData = userDataMap[data.userId] || {};
        
        // Update current department and production if not set
        if (!currentDepartment) setCurrentDepartment(userData.department || 'N/A');
        if (!currentProduction) setCurrentProduction(userData.production || 'N/A');

        return {
          id: doc.id,
          name: userData.name || `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown',
          union: userData.union || 'N/A',
          callTime: data.startTime || 'N/A',
          wrapTime: data.endTime || 'N/A',
          rate: userData.rate || 'N/A',
          department: userData.department || 'N/A',
          production: userData.production || 'N/A',
          userId: data.userId,
          vendor: userData.vendor || '',
          towTag: userData.towTag || '',
          trailerTag: userData.trailerTag || '',
          date: data.date.toDate()
        };
      });
      
      console.log("Processed time entries:", entries);
      setTimeEntries(entries);
      setLoading(false);
    } catch (error) {
      console.error("Detailed error fetching time entries:", error);
      setError(`Failed to fetch time entries: ${error.message}`);
      setLoading(false);
    }
  };

  const calculateTotalHours = (entry) => {
    if (entry.callTime === 'N/A' || entry.wrapTime === 'N/A') return 'N/A';
    const start = parseFloat(entry.callTime);
    const end = parseFloat(entry.wrapTime);
    if (isNaN(start) || isNaN(end)) return 'N/A';
    const diff = end - start;
    return diff.toFixed(2);
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  const handleDateChange = (e) => {
    setSelectedDate(new Date(e.target.value));
  };

  const handlePreviousDayReport = () => {
    const yesterday = new Date(selectedDate);
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectedDate(yesterday);
  };

  const exportToPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });
    const dateStr = selectedDate.toISOString().split('T')[0];
    const departmentStr = departmentMap[currentDepartment] || currentDepartment;
    
    // Add header information
    doc.setFontSize(18);
    doc.text('Time Report', 14, 15);
    doc.setFontSize(12);
    doc.text(`Department: ${departmentStr}`, 14, 25);
    doc.text(`Production: ${currentProduction}`, 14, 32);
    doc.text(`Date: ${selectedDate.toDateString()}`, 14, 39);

    doc.autoTable({
      startY: 45,
      head: [['Name', 'Union', 'Call', 'Wrap', 'Total Hours', 'Rate', 'Vendor', 'Tow/Tag', 'Trailer/Tag']],
      body: timeEntries.map(entry => [
        entry.name,
        entry.union,
        entry.callTime,
        entry.wrapTime,
        calculateTotalHours(entry),
        `$${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr`,
        entry.vendor || '',
        entry.towTag || '',
        entry.trailerTag || ''
      ]),
    });
    doc.save(`${departmentStr}_time_report_${dateStr}.pdf`);
  };

  const exportToExcel = () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const headerData = [
      ['Time Report'],
      [`Department: ${departmentMap[currentDepartment] || currentDepartment}`],
      [`Production: ${currentProduction}`],
      [`Date: ${selectedDate.toDateString()}`],
      [], // Empty row for spacing
    ];

    const ws = XLSX.utils.aoa_to_sheet([
      ...headerData,
      ['Name', 'Union', 'Call', 'Wrap', 'Total Hours', 'Rate', 'Vendor', 'Tow/Tag', 'Trailer/Tag'],
      ...timeEntries.map(entry => [
        entry.union,
        entry.name,
        entry.callTime,
        entry.wrapTime,
        calculateTotalHours(entry),
        `$${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr`,
        entry.vendor || '',
        entry.towTag || '',
        entry.trailerTag || ''
      ])
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Time Report");
    XLSX.writeFile(wb, `time_report_${dateStr}.xlsx`);
  };

  const handleExportToGoogleSheets = () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    alert(`Export to Google Sheets for ${dateStr} is not yet implemented. This would typically involve using the Google Sheets API.`);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) return 'N/A';
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="reports-container">
      <Sidebar />
      <div className="reports-content">
        <header className="page-header">
          <h1>Time Reports</h1>
          <div className="date-navigation">
            <button onClick={handlePreviousDay}>&lt; Previous Day</button>
            <input
              type="date"
              value={selectedDate.toISOString().split('T')[0]}
              onChange={handleDateChange}
            />
            <button onClick={handleNextDay}>Next Day &gt;</button>
          </div>
          <button onClick={handlePreviousDayReport} className="previous-day-report-btn">
            View Previous Day's Report
          </button>
          <p>Viewing entries for: {selectedDate.toDateString()}</p>
          {user.role === 'Supervisor' || user.role === 'supervisor' ? (
            <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
          ) : null}
        </header>
        <main>
          <div className="time-report-table">
            <div className="export-buttons">
              <button onClick={exportToPDF}>Export to PDF</button>
              <button onClick={exportToExcel}>Export to Excel</button>
              {/* <button onClick={handleExportToGoogleSheets}>Export to Google Sheets</button> */}
            </div>
            {loading ? (
              <p>Loading time entries...</p>
            ) : error ? (
              <p className="error-message">{error}</p>
            ) : timeEntries.length === 0 ? (
              <p>No time entries found for the selected date.</p>
            ) : (
              <>
                <div className="report-header">
                  <p><strong>Department:</strong> {departmentMap[currentDepartment] || currentDepartment}</p>
                  <p><strong>Production:</strong> {currentProduction}</p>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Union</th>
                      <th>Call</th>
                      <th>Wrap</th>
                      <th>Total Hours</th>
                      <th>Rate</th>
                      <th>Vendor</th>
                      <th>Tow/Tag</th>
                      <th>Trailer/Tag</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeEntries.map(entry => (
                      <tr key={entry.id}>
                        <td>{entry.name}</td>
                        <td>{entry.union}</td>
                        <td>{formatTime(entry.callTime)}</td>
                        <td>{formatTime(entry.wrapTime)}</td>
                        <td>{calculateTotalHours(entry)}</td>
                        <td>${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr</td>
                        <td>{entry.vendor || ''}</td>
                        <td>{entry.towTag || ''}</td>
                        <td>{entry.trailerTag || ''}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Reports;