import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, deleteField, query, where, deleteDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import Sidebar from '../components/Sidebar';
import './Vehicles.css';
import '../styles/SharedStyles.css';
import { useLocation } from 'react-router-dom';

// Add this near the top of your file, after the imports
const stateAbbreviations = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

function VehiclesPage() {
  const location = useLocation();
  const [vehicles, setVehicles] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddVendorForm, setShowAddVendorForm] = useState(false);
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    licensePlate: '',
    licensePlateState: '', // Add this line
    unitNumber: '', // Add unitNumber to the newVehicle state
    status: 'available',
    imageUrl: '',
    vendorId: ''
  });
  const [newVendor, setNewVendor] = useState({
    name: '',
    contact: '',
    phone: '',
    email: ''
  });
  const [images, setImages] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [crewMembers, setCrewMembers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState('');
  const [showAddVehicleForm, setShowAddVehicleForm] = useState(location.state?.showAddForm || false);
  const [documents, setDocuments] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [damageImages, setDamageImages] = useState([]);

  const isCrewMember = (role) => role === 'crewMember' || role === 'Crew Member';
  const isSupervisor = (role) => role === 'supervisor' || role === 'Supervisor';
  const isAdmin = (role) => role === 'admin' || role === 'Admin';
  const isProductionAdmin = (role) => role === 'productionAdmin' || role === 'Production Admin';

  useEffect(() => {
    if (user && !isCrewMember(user.role)) {
      fetchVehicles();
      fetchVendors();
      fetchCrewMembers();
      fetchProductions();
    }
  }, [user]);

  const fetchVehicles = async () => {
    setIsLoading(true);
    try {
      const db = getFirestore();
      const vehiclesCollection = collection(db, 'vehicles');
      const querySnapshot = await getDocs(vehiclesCollection);
      const vehiclesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVehicles(vehiclesData);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVendors = async () => {
    try {
      const db = getFirestore();
      const vendorsCollection = collection(db, 'vendors');
      const querySnapshot = await getDocs(vendorsCollection);
      const vendorsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorsData);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError(error.message);
    }
  };

  const fetchCrewMembers = async () => {
    try {
      const db = getFirestore();
      const crewMembersCollection = collection(db, 'users');
      console.log("Attempting to fetch crew members. User role:", user.role);
      const querySnapshot = await getDocs(crewMembersCollection);
      const crewMembersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCrewMembers(crewMembersData);
      console.log("Successfully fetched crew members. Count:", crewMembersData.length);
    } catch (error) {
      console.error('Detailed error fetching crew members:', error);
      let errorMessage = "Error fetching crew members: ";
      if (error.code === 'permission-denied') {
        errorMessage += "Permission denied. Your role may not have access to this data.";
      } else if (error.code === 'unauthenticated') {
        errorMessage += "User is not authenticated. Please log in again.";
      } else {
        errorMessage += error.message || "Unknown error occurred";
      }
      errorMessage += ` (User role: ${user.role}, Error code: ${error.code})`;
      setError(errorMessage);
    }
  };

  const fetchProductions = async () => {
    try {
      const db = getFirestore();
      const productionsCollection = collection(db, 'productions');
      const querySnapshot = await getDocs(productionsCollection);
      const productionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProductions(productionsData);
    } catch (error) {
      console.error('Error fetching productions:', error);
      setError(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle({ ...newVehicle, [name]: value });
  };

  const handleVendorInputChange = (e) => {
    const { name, value } = e.target;
    setNewVendor({ ...newVendor, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(prevImages => [...prevImages, ...files]);
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    setNewDocuments(prevDocuments => [...prevDocuments, ...files]);
  };

  const handleDamageImageChange = (e) => {
    const files = Array.from(e.target.files);
    setDamageImages(prevImages => [...prevImages, ...files]);
  };

  const handleAddVehicle = async (e) => {
    e.preventDefault();
    try {
      let imageUrls = [];
      let damageImageUrls = [];
      let documentUrls = [];
      const storage = getStorage();

      // Upload vehicle images
      for (let image of images) {
        const imageRef = ref(storage, `vehicles/${image.name}`);
        await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);
        imageUrls.push(url);
      }

      // Upload damage images
      for (let image of damageImages) {
        const imageRef = ref(storage, `vehicle_damage/${image.name}`);
        await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);
        damageImageUrls.push(url);
      }

      // Upload documents
      for (let document of newDocuments) {
        const documentRef = ref(storage, `vehicle_documents/${document.name}`);
        await uploadBytes(documentRef, document);
        const url = await getDownloadURL(documentRef);
        documentUrls.push(url);
      }

      const db = getFirestore();
      const vehicleData = { 
        ...newVehicle, 
        imageUrls, 
        damageImageUrls,
        documentUrls,
        createdBy: user.uid 
      };
      
      await addDoc(collection(db, 'vehicles'), vehicleData);
      setNewVehicle({
        make: '',
        model: '',
        year: '',
        licensePlate: '',
        licensePlateState: '',
        unitNumber: '',
        status: 'available',
        imageUrls: [],
        damageImageUrls: [],
        documentUrls: [],
        vendorId: '',
        assignedTo: '',
        productionId: ''
      });
      setImages([]);
      setDamageImages([]);
      setNewDocuments([]);
      fetchVehicles();
      setShowAddForm(false);
    } catch (error) {
      console.error("Error adding vehicle: ", error);
      setError(error.message);
    }
  };

  const handleAddVendor = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      await addDoc(collection(db, 'vendors'), newVendor);
      setNewVendor({
        name: '',
        contact: '',
        phone: '',
        email: ''
      });
      fetchVendors();
      setShowAddVendorForm(false);
    } catch (error) {
      console.error("Error adding vendor: ", error);
      setError(error.message);
    }
  };

  const handleUpdateVehicle = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', selectedVehicle.id);
      
      let updatedData = { ...selectedVehicle };
      
      if (images.length > 0 || newDocuments.length > 0) {
        const storage = getStorage();
        let newImageUrls = [];
        let newDocumentUrls = [];

        for (let image of images) {
          const imageRef = ref(storage, `vehicles/${selectedVehicle.id}/${image.name}`);
          await uploadBytes(imageRef, image);
          const url = await getDownloadURL(imageRef);
          newImageUrls.push(url);
        }

        for (let document of newDocuments) {
          const documentRef = ref(storage, `vehicle_documents/${selectedVehicle.id}/${document.name}`);
          await uploadBytes(documentRef, document);
          const url = await getDownloadURL(documentRef);
          newDocumentUrls.push(url);
        }

        updatedData.imageUrls = [...(updatedData.imageUrls || []), ...newImageUrls];
        updatedData.documentUrls = [...(updatedData.documentUrls || []), ...newDocumentUrls];
      }

      await updateDoc(vehicleRef, updatedData);
      setSelectedVehicle(null);
      setIsEditing(false);
      setImages([]);
      setNewDocuments([]);
      fetchVehicles();
    } catch (error) {
      console.error("Error updating vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveImage = async (vehicleId, imageUrl, imageIndex) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      const updatedImageUrls = vehicleDoc.data().imageUrls.filter((_, index) => index !== imageIndex);
      await updateDoc(vehicleRef, { imageUrls: updatedImageUrls });

      fetchVehicles();
    } catch (error) {
      console.error("Error removing image: ", error);
      setError(error.message);
    }
  };

  const handleAssignVehicle = async (vehicleId, crewMemberId, productionId) => {
    if (!(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role))) {
      setError("You don't have permission to assign vehicles.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: crewMemberId,
        productionId: productionId,
        status: 'in_use'
      });
      fetchVehicles();
    } catch (error) {
      console.error("Error assigning vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveFromProduction = async (vehicleId) => {
    if (!(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role))) {
      setError("Only supervisors, production admins, or admins can remove vehicles from production.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: '',
        productionId: '',
        status: 'available'
      });
      fetchVehicles();
    } catch (error) {
      console.error("Error removing vehicle from production: ", error);
      setError(error.message);
    }
  };

  const handleAttachToProduction = async () => {
    if (!selectedVehicle || !selectedProduction) {
      setError("Please select both a vehicle and a production.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', selectedVehicle.id), {
        productionId: selectedProduction,
        status: 'in_use'
      });
      fetchVehicles();
      setSelectedVehicle(null);
      setSelectedProduction('');
    } catch (error) {
      console.error("Error attaching vehicle to production: ", error);
      setError(error.message);
    }
  };

  const handleRemoveVehicle = async (vehicleId) => {
    if (!(isAdmin(user.role) || isSupervisor(user.role))) {
      setError("Only admins and supervisors can remove vehicles.");
      return;
    }
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, 'vehicles', vehicleId));
      fetchVehicles();
    } catch (error) {
      console.error("Error removing vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveDocument = async (vehicleId, documentUrl, documentIndex) => {
    try {
      const storage = getStorage();
      const documentRef = ref(storage, documentUrl);
      await deleteObject(documentRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      const updatedDocumentUrls = vehicleDoc.data().documentUrls.filter((_, index) => index !== documentIndex);
      await updateDoc(vehicleRef, { documentUrls: updatedDocumentUrls });

      fetchVehicles();
    } catch (error) {
      console.error("Error removing document: ", error);
      setError(error.message);
    }
  };

  if (isLoading) {
    return <div className="loading">Loading vehicles...</div>;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="vehicles-container">
      <Sidebar />
      <div className="vehicles-content">
        <header className="page-header">
          <h1>Production Vehicles</h1>
          <p>Manage production vehicles</p>
        </header>
        <main>
          <button className="add-vehicle-btn" onClick={() => setShowAddVehicleForm(true)}>
            Add New Vehicle
          </button>
          {showAddVehicleForm && (
            <section className="add-vehicle">
              <h2>Add New Vehicle</h2>
              <form onSubmit={handleAddVehicle}>
                <div className="form-group">
                  <label htmlFor="make">Make:</label>
                  <input
                    type="text"
                    id="make"
                    name="make"
                    value={newVehicle.make}
                    onChange={handleInputChange}
                    placeholder="Make"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="model">Model:</label>
                  <input
                    type="text"
                    id="model"
                    name="model"
                    value={newVehicle.model}
                    onChange={handleInputChange}
                    placeholder="Model"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="year">Year:</label>
                  <input
                    type="number"
                    id="year"
                    name="year"
                    value={newVehicle.year}
                    onChange={handleInputChange}
                    placeholder="Year"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="licensePlate">License Plate:</label>
                  <input
                    type="text"
                    id="licensePlate"
                    name="licensePlate"
                    value={newVehicle.licensePlate}
                    onChange={handleInputChange}
                    placeholder="License Plate"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="licensePlateState">License Plate State:</label>
                  <select
                    id="licensePlateState"
                    name="licensePlateState"
                    value={newVehicle.licensePlateState}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select State</option>
                    {stateAbbreviations.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="unitNumber">Unit Number:</label>
                  <input
                    type="text"
                    id="unitNumber"
                    name="unitNumber"
                    value={newVehicle.unitNumber}
                    onChange={handleInputChange}
                    placeholder="Unit Number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vendorId">Vendor:</label>
                  <select
                    id="vendorId"
                    name="vendorId"
                    value={newVehicle.vendorId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Vendor</option>
                    {vendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="vehicleImage">Vehicle Pictures:</label>
                  <input
                    type="file"
                    id="vehicleImage"
                    onChange={handleImageChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="damageImages">Damage Pictures:</label>
                  <input
                    type="file"
                    id="damageImages"
                    onChange={handleDamageImageChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vehicleDocuments">Document Pictures:</label>
                  <input
                    type="file"
                    id="vehicleDocuments"
                    onChange={handleDocumentChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <button type="submit">Add Vehicle</button>
                <button type="button" onClick={() => setShowAddVehicleForm(false)}>Cancel</button>
              </form>
            </section>
          )}
          <section className="vehicle-list">
            <h2>Vehicle List</h2>
            <ul>
              {vehicles.map((vehicle) => (
                <li key={vehicle.id}>
                  {vehicle.imageUrls && vehicle.imageUrls.length > 0 && (
                    <div className="vehicle-images-container">
                      {vehicle.imageUrls.map((imageUrl, index) => (
                        <div key={index} className="vehicle-image-wrapper">
                          <img src={imageUrl} alt={`${vehicle.make} ${vehicle.model} ${index + 1}`} className="vehicle-image small" />
                          <button onClick={() => handleRemoveImage(vehicle.id, imageUrl, index)}>Remove</button>
                        </div>
                      ))}
                    </div>
                  )}
                  <h3>{vehicle.make} {vehicle.model} ({vehicle.year})</h3>
                  <p>License Plate: {vehicle.licensePlate} ({vehicle.licensePlateState})</p>
                  <p>Unit Number: {vehicle.unitNumber}</p>
                  <p>Status: {vehicle.status}</p>
                  <p>Vendor: {vendors.find(v => v.id === vehicle.vendorId)?.name || 'Not assigned'}</p>
                  <p>Production: {productions.find(p => p.id === vehicle.productionId)?.name || 'Not assigned'}</p>
                  {vehicle.status === 'in_use' && (
                    <>
                      <p>Assigned to: {crewMembers.find(m => m.id === vehicle.assignedTo)?.name}</p>
                      {(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role)) && (
                        <button onClick={() => handleRemoveFromProduction(vehicle.id)}>
                          Remove from Production
                        </button>
                      )}
                    </>
                  )}
                  {(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role)) && (
                    <select
                      onChange={(e) => handleAssignVehicle(vehicle.id, e.target.value.split(',')[0], e.target.value.split(',')[1])}
                    >
                      <option value="">
                        {vehicle.assignedTo ? 'Reassign to Crew Member' : 'Assign to Crew Member'}
                      </option>
                      {crewMembers.map((member) => (
                        productions.map((production) => (
                          <option key={`${member.id}-${production.id}`} value={`${member.id},${production.id}`}>
                            {member.name} - {production.name}
                          </option>
                        ))
                      ))}
                    </select>
                  )}
                  {/* Add image button */}
                  <input
                    type="file"
                    onChange={(e) => {
                      setImages(Array.from(e.target.files));
                      setSelectedVehicle(vehicle);
                    }}
                    accept="image/*"
                    multiple
                  />
                  {images.length > 0 && selectedVehicle && selectedVehicle.id === vehicle.id && (
                    <button onClick={handleUpdateVehicle}>Upload Images</button>
                  )}
                  <button onClick={() => {
                    setSelectedVehicle(vehicle);
                    setIsEditing(true);
                    setShowAddForm(true);
                  }}>Edit</button>
                  {(isAdmin(user.role) || isSupervisor(user.role)) && (
                    <button onClick={() => handleRemoveVehicle(vehicle.id)}>Remove Vehicle</button>
                  )}
                  <div className="vehicle-documents-container">
                    <h4>Documents</h4>
                    {vehicle.documentUrls && vehicle.documentUrls.map((url, index) => (
                      <div key={index} className="vehicle-document-wrapper">
                        <a href={url} target="_blank" rel="noopener noreferrer">Document {index + 1}</a>
                        <button onClick={() => handleRemoveDocument(vehicle.id, url, index)}>Remove</button>
                      </div>
                    ))}
                  </div>
                  {/* Add document button */}
                  <input
                    type="file"
                    onChange={(e) => {
                      setNewDocuments(Array.from(e.target.files));
                      setSelectedVehicle(vehicle);
                    }}
                    accept=".pdf,.doc,.docx,.txt"
                    multiple
                  />
                  {newDocuments.length > 0 && selectedVehicle && selectedVehicle.id === vehicle.id && (
                    <button onClick={handleUpdateVehicle}>Upload Documents</button>
                  )}
                </li>
              ))}
            </ul>
          </section>
          {(isAdmin(user.role) || isSupervisor(user.role)) && (
            <section className="vendor-section">
              <h2>Vendors</h2>
              {!showAddVendorForm ? (
                <button className="add-vendor-btn" onClick={() => setShowAddVendorForm(true)}>
                  Add New Vendor
                </button>
              ) : (
                <form onSubmit={handleAddVendor} className="add-vendor-form">
                  <input
                    type="text"
                    name="name"
                    value={newVendor.name}
                    onChange={handleVendorInputChange}
                    placeholder="Vendor Name"
                    required
                  />
                  <input
                    type="text"
                    name="contact"
                    value={newVendor.contact}
                    onChange={handleVendorInputChange}
                    placeholder="Contact Person"
                  />
                  <input
                    type="tel"
                    name="phone"
                    value={newVendor.phone}
                    onChange={handleVendorInputChange}
                    placeholder="Phone Number"
                  />
                  <input
                    type="email"
                    name="email"
                    value={newVendor.email}
                    onChange={handleVendorInputChange}
                    placeholder="Email"
                  />
                  <button type="submit">Add Vendor</button>
                  <button type="button" onClick={() => setShowAddVendorForm(false)}>Cancel</button>
                </form>
              )}
            </section>
          )}
          {(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role)) && (
            <section className="attach-vehicle">
              <h2>Attach Vehicle to Production</h2>
              <select
                value={selectedVehicle ? selectedVehicle.id : ''}
                onChange={(e) => setSelectedVehicle(vehicles.find(v => v.id === e.target.value))}
              >
                <option value="">Select Vehicle</option>
                {vehicles.filter(v => !v.productionId).map((vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.make} {vehicle.model} ({vehicle.licensePlate})
                  </option>
                ))}
              </select>
              <select
                value={selectedProduction}
                onChange={(e) => setSelectedProduction(e.target.value)}
              >
                <option value="">Select Production</option>
                {productions.map((production) => (
                  <option key={production.id} value={production.id}>
                    {production.name}
                  </option>
                ))}
              </select>
              <button onClick={handleAttachToProduction}>Attach to Production</button>
            </section>
          )}
        </main>
      </div>
      {error && <div className="error-message">Error: {error}</div>}
    </div>
  );
}

export default VehiclesPage;