import React, { useContext, useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../services/firebase';
import { collection, query, where, onSnapshot, addDoc, Timestamp, orderBy } from 'firebase/firestore';
import './Messages.css';
import '../styles/SharedStyles.css';

function Messages() {
  const { user } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const q = query(collection(db, "chats"), where("users", "array-contains", user.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chatsList = [];
      querySnapshot.forEach((doc) => {
        chatsList.push({ id: doc.id, ...doc.data() });
      });
      setChats(chatsList);
    });
    return () => unsubscribe();
  }, [user.uid]);

  useEffect(() => {
    if (currentChat) {
      const q = query(
        collection(db, "messages"),
        where("chatId", "==", currentChat.id),
        orderBy("createdAt")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messagesList = [];
        querySnapshot.forEach((doc) => {
          messagesList.push({ id: doc.id, ...doc.data() });
        });
        setMessages(messagesList);
      });
      return () => unsubscribe();
    }
  }, [currentChat]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    await addDoc(collection(db, "messages"), {
      chatId: currentChat.id,
      senderId: user.uid,
      text: newMessage,
      createdAt: Timestamp.now(),
    });

    setNewMessage("");
  };

  return (
    <div className="messages-container">
      <Sidebar />
      <div className="messages-content">
        <header className="page-header">
          <h1>Messages</h1>
          <p>Communicate with your team</p>
        </header>
        <main className="chat-main">
          <div className="chat-sidebar">
            <h2>Chats</h2>
            <ul>
              {chats.map((chat) => (
                <li key={chat.id} onClick={() => setCurrentChat(chat)}>
                  {chat.users.find(id => id !== user.uid)}
                </li>
              ))}
            </ul>
          </div>
          <div className="chat-messages">
            {currentChat ? (
              <>
                <div className="messages-list">
                  {messages.map((message) => (
                    <div key={message.id} className={`message ${message.senderId === user.uid ? 'sent' : 'received'}`}>
                      <p>{message.text}</p>
                    </div>
                  ))}
                </div>
                <form onSubmit={handleSendMessage} className="message-input">
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                  />
                  <button type="submit">Send</button>
                </form>
              </>
            ) : (
              <p>Select a chat to start messaging</p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Messages;