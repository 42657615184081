import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../components/Sidebar';
import './Departments.css';
import '../styles/SharedStyles.css';
import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, query, where } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';

function Departments() {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState({
    name: '',
    headOfDepartment: '',
    description: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [editingDepartment, setEditingDepartment] = useState(null);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      fetchDepartments();
    }
  }, [user]);

  const fetchDepartments = async () => {
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'departments'));
      const departmentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDepartments(departmentList);
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError(`Error fetching departments: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddDepartment = async (e) => {
    e.preventDefault();
    if (newDepartment.name.trim()) {
      try {
        await addDoc(collection(db, 'departments'), newDepartment);
        setNewDepartment({ name: '', headOfDepartment: '', description: '' });
        fetchDepartments();
      } catch (error) {
        console.error('Error adding department:', error);
      }
    }
  };

  const handleUpdateDepartment = async (e) => {
    e.preventDefault();
    if (editingDepartment) {
      try {
        await updateDoc(doc(db, 'departments', editingDepartment.id), editingDepartment);
        setEditingDepartment(null);
        fetchDepartments();
      } catch (error) {
        console.error('Error updating department:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment({ ...newDepartment, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingDepartment({ ...editingDepartment, [name]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDepartments = departments.filter(dept =>
    dept.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return <div>Loading departments...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="departments-container">
      <Sidebar />
      <div className="departments-content">
        <header className="page-header">
          <h1>Departments</h1>
          <p>Organize and manage production departments</p>
        </header>
        <main>
          <section className="add-department">
            <h2>Add New Department</h2>
            <form onSubmit={handleAddDepartment}>
              <input
                type="text"
                name="name"
                value={newDepartment.name}
                onChange={handleInputChange}
                placeholder="Department Name"
                required
              />
              <input
                type="text"
                name="headOfDepartment"
                value={newDepartment.headOfDepartment}
                onChange={handleInputChange}
                placeholder="Head of Department"
              />
              <textarea
                name="description"
                value={newDepartment.description}
                onChange={handleInputChange}
                placeholder="Department Description"
              />
              <button type="submit">Add Department</button>
            </form>
          </section>
          <section className="search-departments">
            <h2>Search Departments</h2>
            <input
              type="text"
              placeholder="Search departments..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </section>
          <section className="department-list">
            <h2>Existing Departments</h2>
            <div className="department-grid">
              {filteredDepartments.map((dept) => (
                <div key={dept.id} className="department-item">
                  <h3>{dept.name}</h3>
                  <p><strong>Head of Department:</strong> {dept.headOfDepartment || 'Not assigned'}</p>
                  <p><strong>Description:</strong> {dept.description || 'No description available'}</p>
                  <button onClick={() => setEditingDepartment(dept)}>Edit</button>
                  {editingDepartment && editingDepartment.id === dept.id && (
                    <div className="edit-department-popup">
                      <form onSubmit={handleUpdateDepartment}>
                        <input
                          type="text"
                          name="name"
                          value={editingDepartment.name}
                          onChange={handleEditInputChange}
                          placeholder="Department Name"
                          required
                        />
                        <input
                          type="text"
                          name="headOfDepartment"
                          value={editingDepartment.headOfDepartment}
                          onChange={handleEditInputChange}
                          placeholder="Head of Department"
                        />
                        <textarea
                          name="description"
                          value={editingDepartment.description}
                          onChange={handleEditInputChange}
                          placeholder="Department Description"
                        />
                        <button type="submit">Update</button>
                        <button type="button" onClick={() => setEditingDepartment(null)}>Cancel</button>
                      </form>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default Departments;