import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure this path is correct
import Sidebar from '../components/Sidebar';
import { useAuth } from '../hooks/useAuth'; // Add this import
import './Dashboard.css'; // Optional: for custom styles
import '../styles/SharedStyles.css';
import { Link } from 'react-router-dom';

function Dashboard() {
  const { user } = useAuth(); // Add this line to get the user object
  const [activeProductions, setActiveProductions] = useState(0);
  const [totalCrewMembers, setTotalCrewMembers] = useState(0);
  const [upcomingShoots, setUpcomingShoots] = useState(0);
  const [productionStatus, setProductionStatus] = useState({
    preProduction: 0,
    inProduction: 0,
    postProduction: 0
  });
  const [recentActivities, setRecentActivities] = useState([]);
  const [crewMemberCount, setCrewMemberCount] = useState(0);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch active productions
        const productionsQuery = query(collection(db, 'productions'), where('status', '==', 'active'));
        const productionsSnapshot = await getDocs(productionsQuery);
        setActiveProductions(productionsSnapshot.size);

        // Fetch total crew members
        const crewMembersSnapshot = await getDocs(collection(db, 'crewMembers'));
        setTotalCrewMembers(crewMembersSnapshot.size);

        // Fetch upcoming shoots
        const today = new Date();
        const shootsQuery = query(
          collection(db, 'shoots'),
          where('date', '>', today)
        );
        const shootsSnapshot = await getDocs(shootsQuery);
        setUpcomingShoots(shootsSnapshot.size);

        // Fetch production status
        const statusSnapshot = await getDoc(doc(db, 'statistics', 'productionStatus'));
        if (statusSnapshot.exists()) {
          setProductionStatus(statusSnapshot.data());
        }

        // Fetch recent activities
        const activitiesQuery = query(
          collection(db, 'activities'),
          where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
        );
        const activitiesSnapshot = await getDocs(activitiesQuery);
        const recentActivitiesList = activitiesSnapshot.docs.map(doc => doc.data().description);
        setRecentActivities(recentActivitiesList);

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    const fetchCrewMembers = async () => {
      console.log('User object:', user);
      if (user && (user.role === 'supervisor' || user.role === 'Supervisor')) {
        console.log('User is a supervisor');
        if (user.department && user.productionId) {
          console.log('Fetching crew members for department:', user.department, 'and production:', user.productionId);
          const crewMembersRef = collection(db, 'users');
          const q = query(
            crewMembersRef, 
            where('department', '==', user.department),
            where('productionId', '==', user.productionId)
          );
          const querySnapshot = await getDocs(q);
          console.log('Crew members found:', querySnapshot.size);
          setCrewMemberCount(querySnapshot.size);
        } else {
          console.error('User department or productionId is undefined');
          setCrewMemberCount(0);
        }
      } else {
        console.log('User is not a supervisor');
        setCrewMemberCount(0);
      }
    };

    fetchCrewMembers();
  }, [user]); // Add user as a dependency

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <header className="page-header">
          <h1>Dashboard</h1>
          <p>Overview of your production activities</p>
        </header>
        <main>
          <div className="dashboard-grid">
            <div className="dashboard-card">
              <h3>Active Productions</h3>
              <p className="dashboard-number">{activeProductions}</p>
            </div>
            <div className="dashboard-card">
              <h3>Total Crew Members</h3>
              <p className="dashboard-number">{crewMemberCount}</p>
            </div>
            <div className="dashboard-card">
              <h3>Upcoming Shoots</h3>
              <p className="dashboard-number">{upcomingShoots}</p>
            </div>
            <div className="dashboard-card large">
              <h3>Production Status</h3>
              <div className="production-counts">
                <div>
                  <p>Pre-production</p>
                  <p className="dashboard-number">{productionStatus.preProduction}</p>
                </div>
                <div>
                  <p>In Production</p>
                  <p className="dashboard-number">{productionStatus.inProduction}</p>
                </div>
                <div>
                  <p>Post-production</p>
                  <p className="dashboard-number">{productionStatus.postProduction}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-bottom">
            <div className="dashboard-recent">
              <h3>Recent Activities</h3>
              <ul>
                {recentActivities.map((activity, index) => (
                  <li key={index}>{activity}</li>
                ))}
              </ul>
            </div>
            <div className="dashboard-actions">
              <h3>Quick Actions</h3>
              <button className="dashboard-action-btn">Create New Production</button>
              <button className="dashboard-action-btn">Add Crew Member</button>
              <button className="dashboard-action-btn">View Reports</button>
              <Link to="/view-receipts" className="dashboard-action-btn">View Receipts</Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
