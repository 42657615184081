import React, { useState, useContext, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import './Settings.css';
import '../styles/SharedStyles.css';

function Settings() {
  const { user } = useContext(AuthContext);
  const [settings, setSettings] = useState({
    emailNotifications: true,
    pushNotifications: false,
    darkMode: false,
    language: 'en',
    timeZone: 'UTC',
  });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (user) {
      fetchUserSettings();
    }
  }, [user]);

  const fetchUserSettings = async () => {
    try {
      const docRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setSettings(docSnap.data());
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user settings:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const saveSettings = async () => {
    try {
      const docRef = doc(db, 'userSettings', user.uid);
      await updateDoc(docRef, settings);
      setMessage('Settings saved successfully!');
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error("Error saving settings:", error);
      setMessage('Error saving settings. Please try again.');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setPasswordError('');

    if (newPassword !== confirmNewPassword) {
      setPasswordError("New passwords don't match.");
      return;
    }

    try {
      const auth = getAuth();

      // Update password without re-authentication
      await updatePassword(auth.currentUser, newPassword);

      setMessage('Password updated successfully!');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error("Error updating password:", error);
      setPasswordError('Failed to update password. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="settings-container">
      <Sidebar />
      <div className="settings-content">
        <header className="page-header">
          <h1>Settings</h1>
          <p>Configure your application preferences</p>
        </header>
        <main>
          <div className="settings-tile">
            <h2>Notification Settings</h2>
            <div className="setting-item">
              <label>
                <input
                  type="checkbox"
                  name="emailNotifications"
                  checked={settings.emailNotifications}
                  onChange={handleChange}
                />
                Receive Email Notifications
              </label>
            </div>
            <div className="setting-item">
              <label>
                <input
                  type="checkbox"
                  name="pushNotifications"
                  checked={settings.pushNotifications}
                  onChange={handleChange}
                />
                Receive Push Notifications
              </label>
            </div>

            <h2>Display Settings</h2>
            <div className="setting-item">
              <label>
                <input
                  type="checkbox"
                  name="darkMode"
                  checked={settings.darkMode}
                  onChange={handleChange}
                />
                Dark Mode
              </label>
            </div>

            <h2>Language and Region</h2>
            <div className="setting-item">
              <label>
                Language:
                <select name="language" value={settings.language} onChange={handleChange}>
                  <option value="en">English</option>
                  <option value="es">Español</option>
                  <option value="fr">Français</option>
                </select>
              </label>
            </div>
            <div className="setting-item">
              <label>
                Time Zone:
                <select name="timeZone" value={settings.timeZone} onChange={handleChange}>
                  <option value="UTC">UTC</option>
                  <option value="America/New_York">Eastern Time</option>
                  <option value="America/Los_Angeles">Pacific Time</option>
                  {/* Add more time zones as needed */}
                </select>
              </label>
            </div>

            <h2>Password Reset</h2>
            <form onSubmit={handlePasswordReset} className="password-reset-form">
              <div className="setting-item">
                <label htmlFor="newPassword">New Password:</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="setting-item">
                <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="reset-password-btn">Reset Password</button>
            </form>
            {passwordError && <p className="error-message">{passwordError}</p>}

            <button onClick={saveSettings} className="save-settings-btn">Save Settings</button>
            {message && <p className="settings-message">{message}</p>}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Settings;