import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import Sidebar from '../components/Sidebar';
import './Productions.css';
import '../styles/SharedStyles.css';

function Productions() {
  const [productions, setProductions] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [newProduction, setNewProduction] = useState({
    name: '',
    status: 'active',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    budget: '',
    description: ''
  });

  useEffect(() => {
    fetchProductions();
  }, []);

  const fetchProductions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'productions'));
      const productionList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate.toDate().toLocaleDateString(),
        endDate: doc.data().endDate.toDate().toLocaleDateString()
      }));
      setProductions(productionList);
    } catch (error) {
      console.error("Error fetching productions:", error);
      setError("Failed to fetch productions. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduction({ ...newProduction, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!newProduction.name || !newProduction.startDate || !newProduction.endDate || !newProduction.budget) {
      setError("All required fields must be filled");
      return;
    }

    try {
      console.log('Current user:', user); // Log the current user object
      console.log('Submitting new production:', newProduction);
      const startTimestamp = Timestamp.fromDate(new Date(`${newProduction.startDate}T${newProduction.startTime}`));
      const endTimestamp = Timestamp.fromDate(new Date(`${newProduction.endDate}T${newProduction.endTime}`));
      
      const productionData = {
        ...newProduction,
        startDate: startTimestamp,
        endDate: endTimestamp,
        budget: Number(newProduction.budget),
        createdBy: user.uid,
        createdAt: Timestamp.now()
      };
      console.log('Production data to be submitted:', productionData);
      
      const docRef = await addDoc(collection(db, 'productions'), productionData);
      console.log('Document written with ID: ', docRef.id);
      
      setNewProduction({
        name: '',
        status: 'active',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        budget: '',
        description: ''
      });
      fetchProductions();
      setError(null);
    } catch (error) {
      console.error("Error adding production:", error);
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      setError(`Error adding production: ${error.message}`);
    }
  };

  const canCreateProduction = user && (user.role === 'superAdmin' || user.role === 'admin' || user.role === 'productionAdmin');

  return (
    <div className="productions-container">
      <Sidebar />
      <div className="productions-content">
        <header className="page-header">
          <h1>Productions</h1>
          <p>Manage your film and TV productions</p>
        </header>
        <main>
          {canCreateProduction && (
            <section className="add-production">
              <h2>Add New Production</h2>
              {error && <div className="error-message">{error}</div>}
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={newProduction.name}
                  onChange={handleInputChange}
                  placeholder="Production Name"
                  required
                />
                <select
                  name="status"
                  value={newProduction.status}
                  onChange={handleInputChange}
                  required
                >
                  <option value="active">Active</option>
                  <option value="completed">Completed</option>
                  <option value="on_hold">On Hold</option>
                </select>
                <input
                  type="date"
                  name="startDate"
                  value={newProduction.startDate}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="time"
                  name="startTime"
                  value={newProduction.startTime}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="date"
                  name="endDate"
                  value={newProduction.endDate}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="time"
                  name="endTime"
                  value={newProduction.endTime}
                  onChange={handleInputChange}
                  required
                />
                {/*<input
                  type="number"
                  name="budget"
                  value={newProduction.budget}
                  onChange={handleInputChange}
                  placeholder="Budget"
                  required
                />*/}
                <textarea
                  name="description"
                  value={newProduction.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                  required
                ></textarea>
                <button type="submit">Add Production</button>
              </form>
            </section>
          )}
          <section className="production-list">
            <h2>Existing Productions</h2>
            <ul>
              {productions.map((production) => (
                <li key={production.id}>
                  <h3>{production.name}</h3>
                  <p>Status: {production.status}</p>
                  <p>Start Date: {production.startDate}</p>
                  <p>End Date: {production.endDate}</p>
                  <p>Budget: ${production.budget}</p>
                  <p>{production.description}</p>
                </li>
              ))}
            </ul>
          </section>
        </main>
      </div>
    </div>
  );
}

export default Productions;