import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signUp } from '../services/auth';
import { createUserDocument } from '../services/firestore';
import { AuthContext } from '../contexts/AuthContext';
import '../styles/AuthPages.css';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('crew_member');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    firstName: '',
    lastName: '',
    department: '',
    production: '',
    feedback: ''
  });
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // Redirect if user is already logged in
  if (user) {
    navigate('/welcome');
  }

  console.log("User from AuthContext in Signup:", user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      console.log("Attempting to sign up user...");
      const { user } = await signUp(email, password);
      console.log("User signed up successfully:", user);
      
      console.log("Creating user document...");
      await createUserDocument(user, { 
        role: role,
        waitlisted: true,
        firstName: '',
        lastName: '',
        department: '',
        production: '',
        feedback: ''
      });
      console.log("User document created successfully");
      
      // Show additional info popup
      setShowAdditionalInfo(true);
    } catch (error) {
      console.error("Signup error:", error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please use a different email or try logging in.');
      } else if (error.code === 'auth/invalid-email') {
        setError('The email address is not valid.');
      } else if (error.code === 'auth/weak-password') {
        setError('The password is too weak. Please use a stronger password.');
      } else {
        setError('Failed to create an account. Please try again.');
      }
      setLoading(false);
    }
  };

  // Commented out Google Sign In
  // const handleGoogleSignIn = async () => {
  //   setError('');
  //   setLoading(true);
  //   try {
  //     const { user } = await signInWithGoogle();
  //     await createUserDocument(user, { role: role });
  //     navigate('/welcome');
  //   } catch (error) {
  //     setError('Failed to sign in with Google: ' + error.message);
  //   }
  //   setLoading(false);
  // };

  if (showAdditionalInfo) {
    return (
      <div className="auth-container">
        <div className="auth-form-container">
          <h2>Additional Information</h2>
          <form onSubmit={(e) => {
            e.preventDefault();
            // Handle additional info submission here
            console.log("Additional info:", additionalInfo);
            navigate('/welcome');
          }}>
            <input
              type="text"
              placeholder="First Name"
              value={additionalInfo.firstName}
              onChange={(e) => setAdditionalInfo({...additionalInfo, firstName: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              value={additionalInfo.lastName}
              onChange={(e) => setAdditionalInfo({...additionalInfo, lastName: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Department"
              value={additionalInfo.department}
              onChange={(e) => setAdditionalInfo({...additionalInfo, department: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Production"
              value={additionalInfo.production}
              onChange={(e) => setAdditionalInfo({...additionalInfo, production: e.target.value})}
              required
            />
            <textarea
              placeholder="Feedback"
              value={additionalInfo.feedback}
              onChange={(e) => setAdditionalInfo({...additionalInfo, feedback: e.target.value})}
            ></textarea>
            <button type="submit" className="auth-button">
              Complete Signup
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-form-container">
        <Link to="/" className="close-button">&times;</Link>
        <h2>Sign Up</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="crew_member">Crew Member</option>
              <option value="supervisor">Supervisor</option>
              <option value="Supervisor">Supervisor (Capital S)</option>
              <option value="production_admin">Production Admin</option>
              <option value="app_admin">App Admin</option>
            </select>
          </div>
          <button type="submit" className="auth-button" disabled={loading}>
            {loading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </form>
        {/* Commented out Google Sign In button */}
        {/* <button onClick={handleGoogleSignIn} className="google-button" disabled={loading}>
          {loading ? 'Processing...' : 'Sign up with Google'}
        </button> */}
        <p className="auth-link">
          Already have an account? <Link to="/login">Log in</Link>
        </p>
      </div>
    </div>
  );
}

export default Signup;