import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { signOut } from '../services/auth';
import '../styles/WelcomePage.css';
import logo from '../Assets/Slate-Logo.png';

function WelcomePage() {
  const [feedback, setFeedback] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Feedback submitted:', feedback);
    alert('Thank you for your feedback!');
    setFeedback('');
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <div className="welcome-container">
      <header className="welcome-header">
        <img src={logo} alt="SlateFusion Logo" className="welcome-logo" />
        <h1>Welcome to SlateFusion</h1>
      </header>
      <main className="welcome-content">
        <section className="welcome-section">
          <h2>Thank you for joining our waitlist while we are in our testing phase. We look forward to working with you in the near future. </h2>
          <p>SlateFusion is designed to revolutionize film and TV production management. While we're still in development, we're excited to share our vision with you.</p>
        </section>
        <section className="welcome-section">
          <h3>Key Features (Coming Soon):</h3>
          <ul>
            <li>Real-time production tracking</li>
            <li>Crew management and scheduling</li>
            <li>Resource optimization</li>
            <li>Comprehensive reporting and analytics</li>
            <li>Secure communication tools</li>
          </ul>
        </section>
        <section className="welcome-section">
          <h3>What to Expect:</h3>
          <p>During this testing phase, we're focusing on gathering feedback and refining our user experience. While full functionality isn't available yet, your insights are invaluable in shaping the future of SlateFusion.</p>
        </section>
        <section className="welcome-section">
          <h3>Stay Tuned:</h3>
          <p>We'll be rolling out features gradually. Keep an eye on your email for updates and opportunities to explore new functionalities as they become available.</p>
        </section>
        <section className="welcome-section feedback-section">
          <h3>Help Us Shape SlateFusion</h3>
          <p>Your insights are crucial in developing a solution that truly meets your needs. Please share:</p>
          <ul>
            <li>Current challenges in film production that software could solve</li>
            <li>Tools you're currently using and their pros/cons</li>
            <li>Features you wish existed in production management software</li>
          </ul>
          <form onSubmit={handleSubmit}>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Share your experiences, ideas, and wishes for the ideal production management solution..."
              rows="6"
            ></textarea>
            <button type="submit">Submit Feedback</button>
          </form>
        </section>
      </main>
      <footer className="welcome-footer">
        <p>&copy; 2024 SlateFusion. All rights reserved.</p>
      </footer>
      <div className="user-info">
        <p>{user.email}</p>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </div>
  );
}

export default WelcomePage;